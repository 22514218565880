import { Case, CommunicationStatus, DocumentStatus, DocumentType, Jurisdiction, Source, ViewCommunication } from 'briefpoint-client';
import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Row, Table } from 'react-bootstrap';
import { FormModal } from 'components/Modals/Modal';
import Loading from 'components/Loading';
import { DocumentClient, GetDocumentSubTypeName } from 'services/DocumentService';
import { formatShortDate, toDateOrNow } from 'utils/dateUtils';
import { dispatchSort, DocumentIcon, DueSoon, FinishButton, FinishDraftButton, formatLocalDate, ReviewButton, StartButton } from 'components/DocumentList/DocumentList';
import DocumentActionsMenu from 'components/DocumentList/DocumentActionsMenu';
import './DocumentTable.scss';
import { useCommunicationApi } from 'hooks/useApi';
import { useHistory } from 'react-router-dom';
import { DocumentRowWithReviewClient } from 'components/DocumentList/DocumentRowWithReviewClient';
import useConfig, { BRIDGE_FF } from 'hooks/useConfig';
import { useAuth } from 'hooks/useAuth';
import { sortCommunications } from 'screens/ClientFeedback';


export function Action(document: DocumentClient) {

  if (document.source === Source.InternalGenerated) {
    return (
      <FinishDraftButton document={document} />
    )
  }

  switch (document.status) {
    case DocumentStatus.InfoConfirmed:
      return (
        <FinishButton documentId={document.id} type={document.info?.documentType ?? DocumentType.Unknown} />
      );
    case DocumentStatus.Complete:
      return (
        <ReviewButton documentId={document.id} type={document.info?.documentType ?? DocumentType.Unknown} />
      );
    case DocumentStatus.Processed:
      return (
        <StartButton documentId={document.id} />
      );
    case DocumentStatus.Unknown:
    case DocumentStatus.Uploaded:
    case DocumentStatus.Processing:
      return (
        'Processing...'
      )
    default:
      return (
        DocumentStatus[document.status!]
      );
  }
}


interface Props {
  documents: DocumentClient[] | undefined;
  _case?: Case;
  jurisdiction?: Jurisdiction;
  keyPrefix: string;
  uploadDocAction?: (e: any) => void;
  updateDocument: (d: DocumentClient) => Promise<void>;
  archiveDocument: (d: DocumentClient) => Promise<void>;
  downloadForm: (document: DocumentClient, formId: string, formTypeName: string) => Promise<void>;
  downloadRequest: (document: DocumentClient) => Promise<void>;
}

export default function DocumentsTable({ documents, _case, jurisdiction, keyPrefix: key, uploadDocAction, updateDocument, archiveDocument, downloadForm, downloadRequest }: Props) {
  const [sortColumn, setSortColumns] = useState('uploadDate');
  const [sortDirectionAscending, setSortDirectionAscending] = useState(false);
  const [showEditDueDate, setShowEditDueDate] = useState<DocumentClient | undefined>();
  const [dueDateOverride, setDueDateOverride] = useState<string | undefined>();
  const history = useHistory();
  const communicationApi = useCommunicationApi();
  const [communications, setCommunications] = useState<ViewCommunication[] | undefined>();
  const [, featureFlags] = useConfig();
  const { user, firm } = useAuth()!;
  const useBridge = featureFlags()[BRIDGE_FF] ? featureFlags()[BRIDGE_FF](user, firm) : false;

  function changeSort(column: string) {
    if (column === sortColumn) {
      setSortDirectionAscending(!sortDirectionAscending);
    } else {
      setSortColumns(column);
      setSortDirectionAscending(false);
    }
  }

  function addDueDateDays(days?: number) {
    const existing = toDateOrNow(showEditDueDate?.info?.dueDateOverridden || showEditDueDate?.info?.dueDate);

    const newDate = days ? existing.plus({ days }) : existing.plus({ month: 1 });

    setDueDateOverride(newDate.toFormat('yyyy-MM-dd'));
  }

  function closeDueDateModal() {
    setShowEditDueDate(undefined);
    setDueDateOverride(undefined);
  }

  async function saveDocument(showEditDueDate: DocumentClient): Promise<void> {
    showEditDueDate.info!.dueDateOverridden = dueDateOverride;
    await updateDocument(showEditDueDate);
    closeDueDateModal();
  }

  function shouldShowInfo(doc: DocumentClient, details: any, placeholder: string = '-') {
    return doc.status === DocumentStatus.Complete || doc.status === DocumentStatus.InfoConfirmed ? details : placeholder;
  }

  function createReview(doc: DocumentClient) {
    history.push(`/documents/${doc.id}/client-feedback`);
  }

  const loadCommunication = useCallback(async () => {
    const coms = await communicationApi.communicationGetFirmCommunications({ lowerBound: CommunicationStatus.Pending, upperBound: CommunicationStatus.Complete });
    setCommunications(coms);
  }, [communicationApi]);

  useEffect(() => {
    if (!communications && useBridge) {
      loadCommunication();
    }
  }, [communications, useBridge, loadCommunication]);

  async function handleRemoveClientAccess(communication: ViewCommunication) {
    await communicationApi.communicationUpdateCommunication({
      id: communication.id!, putClientCommunication: {
        ...communication,
        sharedWithObjectType: undefined,
        sharedWithObjectId: undefined,
        status: CommunicationStatus.Closed
      }
    });
    setCommunications(communications?.filter(c => c.id !== communication.id));
  }

  function getCommunicationForDoc(doc: DocumentClient) {
    return communications?.filter(c => c.objectId === doc.id)?.sort(sortCommunications)?.[0];
  }

  function getListTableHeadersBridge() {
    return (
      <thead>
        <tr>
          <th></th>
          <th
            onClick={() => changeSort('name')}
            className={`clickable ${sortColumn === 'name' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>
            Document Name
          </th>
          <th style={{ width: '93px' }}
            onClick={() => changeSort('type')}
            className={`clickable ${sortColumn === 'type' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>
            Doc Type
          </th>
          <th style={{ width: '93px' }}
            onClick={() => changeSort('uploadDate')}
            className={`clickable ${sortColumn === 'uploadDate' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>
            Upload Date
          </th>
          <th
            className={`clickable ${sortColumn === 'propoundingParty' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}
            onClick={() => changeSort('propoundingParty')}>
            Propounding Party
          </th>
          <th
            className={`clickable ${sortColumn === 'respondingParty' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}
            onClick={() => changeSort('respondingParty')}>
            Responding Party
          </th>
          {!!jurisdiction?.supportsDueDates && <>
            <th onClick={() => changeSort('realDueDate')}
              style={{ width: '93px' }}
              className={`clickable ${sortColumn === 'realDueDate' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>Due Date</th>
            <th></th>
          </>}
          <th style={{ width: '275px' }}>
            Client Activity
          </th>
          <th style={{ width: '120px' }}></th>
          <th></th>
        </tr>
      </thead>
    )
  }

  function getListTableHeaders() {
    return (
      <thead>
        <tr className="table-header">
          <th></th>
          <th
            className={`clickable ${sortColumn === 'name' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}
            onClick={() => changeSort('name')}>
            Document Name
          </th>
          <th
            className={`clickable ${sortColumn === 'type' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}
            onClick={() => changeSort('type')}>
            Document Type
          </th>
          <th style={{ width: '93px' }}
            onClick={() => changeSort('uploadDate')}
            className={`clickable ${sortColumn === 'uploadDate' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>
            Date
          </th>
          <th
            className={`clickable ${sortColumn === 'propoundingParty' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}
            onClick={() => changeSort('propoundingParty')}>
            Propounding Party
          </th>
          <th
            className={`clickable ${sortColumn === 'respondingParty' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}
            onClick={() => changeSort('respondingParty')}>
            Responding Party
          </th>
          {uploadDocAction && jurisdiction?.supportsDueDates &&
            <>
              <th onClick={() => changeSort('realDueDate')} className={`clickable ${sortColumn === 'realDueDate' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>Due Date</th>
              <th></th>
            </>
          }
          <th className="table-header-cell" scope="col"></th>
          <th></th>
        </tr>
      </thead>
    )
  }

  return (
    <Loading className='documentsLoading' isLoading={documents === undefined}>
      {documents?.length ?
        <>
          <Row>
            <Table id='document-list-table' striped hover style={{ marginLeft: '12px' }}>
              {useBridge ? getListTableHeadersBridge() : getListTableHeaders()}
              <tbody>
                {documents?.sort((a, b) => dispatchSort(a, b, sortColumn, sortDirectionAscending)).map(d => {
                  if (useBridge) {
                    return (
                      <DocumentRowWithReviewClient
                        _case={_case}
                        doc={d}
                        communication={getCommunicationForDoc(d)}
                        archiveDocument={archiveDocument}
                        editDueDate={!!jurisdiction?.supportsDueDates ? () => setShowEditDueDate(d) : undefined}
                        downloadForm={downloadForm}
                        downloadRequest={downloadRequest}
                        removeClientAccess={handleRemoveClientAccess}
                        createReview={createReview}
                        key={`${key}-${d.id}`}
                        respondingParty={shouldShowInfo(d, d.info?.respondingPartyName, '')}
                        propoundingParty={shouldShowInfo(d, d.info?.propoundingPartyName, '')} />
                    )
                  }

                  return (
                    <tr key={`${key}-${d.id}`} className='table-row'>
                      <td className='icon'>
                        <DocumentIcon extension="pdf" />
                      </td>
                      <td className='name-cell' title={shouldShowInfo(d, d.name?.replace('.pdf', '').replace('.PDF', ''), '')}>{d.name?.replace('.pdf', '').replace('.PDF', '')}</td>
                      <td className='table-cell'>{shouldShowInfo(d, d.info?.documentSubType && GetDocumentSubTypeName(d.info.documentSubType, d.info.jurisdiction ?? ''))}</td>
                      <td className='date-cell'>{formatShortDate(d.uploadDate)}</td>
                      <td className='table-cell' title={shouldShowInfo(d, d.info?.propoundingPartyName, '')}>{shouldShowInfo(d, d.info?.propoundingPartyName)}</td>
                      <td className='table-cell' title={shouldShowInfo(d, d.info?.respondingPartyName, '')}>{shouldShowInfo(d, d.info?.respondingPartyName)}</td>
                      {uploadDocAction && jurisdiction?.supportsDueDates &&
                        <>
                          <td className='date-cell'>{d.source !== Source.InternalGenerated ? shouldShowInfo(d, formatLocalDate(d.info?.dueDateOverridden || d.info?.dueDate || undefined) || <Button className='set-dueDate-button' variant='link' onClick={() => setShowEditDueDate(d)}>Set Due Date</Button>) : '-'}</td>
                          <td>{DueSoon(d.info?.dueDateOverridden || d.info?.dueDate || undefined)}</td>
                        </>}
                      <td className='actions-cell'>{Action(d)}</td>
                      <td className='kebab-cell'>
                        <DocumentActionsMenu _case={_case} document={d} archiveDocument={archiveDocument} editDueDate={!!jurisdiction?.supportsDueDates ? () => setShowEditDueDate(d) : undefined} downloadForm={downloadForm} downloadRequest={downloadRequest} />
                      </td>
                    </tr>)
                })}
              </tbody>
            </Table>
          </Row>
          <FormModal
            show={!!showEditDueDate}
            title={`${showEditDueDate?.info?.dueDateOverridden || showEditDueDate?.info?.dueDate ? 'Edit' : 'Set'} Due Date`}
            size='sm'
            confirmText='Confirm'
            cancelText='Cancel'
            onClose={closeDueDateModal}
            onConfirm={() => saveDocument(showEditDueDate!)}
          >
            {(showEditDueDate?.info?.dueDateOverridden || showEditDueDate?.info?.dueDate) &&
              <div className='add-x-days'>
                Add <Button variant='link' onClick={() => addDueDateDays(7)} >7 days</Button>, <Button variant='link' onClick={() => addDueDateDays(14)}  >14 days</Button>, <Button onClick={() => addDueDateDays()} variant='link' >1 month</Button>
              </div>
            }
            <Form.Control type='date' className='date-picker' value={dueDateOverride || showEditDueDate?.info?.dueDateOverridden || showEditDueDate?.info?.dueDate || undefined}
              onChange={(e) => setDueDateOverride(e.target.value)} />
          </FormModal>
        </>
        : <p>No Documents yet, {uploadDocAction ? <Button variant='link' onClick={uploadDocAction}>{'Upload one Now!'}</Button> : 'Draft one now from the menu above.'}</p>}
    </Loading>
  );
}
