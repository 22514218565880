import { Case, Client, CommunicationStatus, DocumentSubType, DocumentType, ViewCommunication } from 'briefpoint-client';
import { FormModal } from 'components/Modals';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { Badge, Button, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { DocumentClient } from '../../services/DocumentService';
import SelectCaseModal from './SelectCaseModal';
import useDocuments from 'hooks/useDocuments';
import { useAuth } from 'hooks/useAuth';
import { CaliforniaId } from 'components/CaseManagement/AddEditCaseModal';
import { DocumentRow } from './DocumentRow';
import { DocumentRowWithReview } from './DocumentRowWithReview';
import { ReactComponent as PdfIcon } from "../../images/new-pdf-icon.svg";
import { ReactComponent as WordIcon } from "../../images/new-doc-icon.svg";
import { sortCommunications } from 'screens/ClientFeedback';

export function DocumentIcon({ extension }: { extension: string | undefined }) {
  if (!!extension && ['docx', 'doc'].includes(extension)) {
    return <WordIcon className="text-primary fs-2" />;
  }
  return <PdfIcon className="text-danger fs-2" />;
}

export function StartButton({ documentId }: { documentId: string }) {
  return (
    <Link
      className="btn btn-outline-primary btn-sm wide"
      to={`/wizard/${documentId}/source-document-review?document_type=false`}
    >
      Start
    </Link>
  );
}

export function ReviewButton({ documentId, type }: { documentId: string, type: DocumentType }) {
  return (
    <Link className="btn btn-outline-primary btn-sm wide" to={`/wizard/${type === DocumentType.Pleading ? 'propound/' : ''}${documentId}`}>
      Review
    </Link>
  );
}

export function FinishButton({ documentId, type }: { documentId: string, type: DocumentType }) {
  return (
    <Link className="btn btn-outline-primary btn-sm wide" to={`/wizard/${type === DocumentType.Pleading ? 'propound/' : ''}${documentId}`}>
      Continue
    </Link>
  );
}

export function FinishDraftButton({ document }: { document: DocumentClient }) {
  return (
    <Link className="btn btn-outline-primary btn-sm wide" to={`/clients/${document.clientId}/cases/${document.caseId}/generateDiscoveryRequest?documentId=${document.id}`}>
      {document.lastWordDownloadDate ? 'Edit' : 'Finish'}
    </Link>
  );
}

export function DownloadButton({ doc }: { doc: DocumentClient }) {
  const [, , , , , , , , downloadRequest] = useDocuments(false);

  return (
    <Button size='sm' variant='outline-primary wide' onClick={() => downloadRequest(doc)}>
      Download
    </Button>
  );
}

export function DueSoon(dueDate?: string) {
  if (!dueDate) return '​';

  const diff = DateTime.fromISO(dueDate).diff(DateTime.now(), 'days')

  if (diff.days < -1 || diff.days > 7) return '​';

  return (
    diff.days < 0 ? <Badge bg='danger'>Due Today</Badge> : <Badge bg='warning'>Due Soon</Badge>
  );
}

export function NewComm(comm?: ViewCommunication, document?: DocumentClient) {
  if (!comm?.lastActivityDate || comm.status !== CommunicationStatus.Complete) return '​';

  if (!document?.info?.lastViewDate || comm.lastActivityDate < document.info.lastViewDate) {
    return <Badge bg='success'>New</Badge>
  }

  return '​';
}

// convert from yyyy-mm-dd to mm/dd/yyyy
export function formatLocalDate(date?: string) {
  if (!date) return;

  var parts = date.split('-');

  return `${parts[1]}/${parts[2]}/${parts[0]}`
}

export function sortDocumentDates(first: DocumentClient, second: DocumentClient, sortColumn: string, sortDirectionAscending: boolean) {
  // I couldn't get realDueDate as a computed property to work, sorry
  const a = ((sortColumn === 'realDueDate' ? toDateOrMin(first.info?.dueDateOverridden || first.info?.dueDate) : first[sortColumn as keyof typeof first] as Date) ?? new Date(-8640000000000000)).getTime();
  const b = ((sortColumn === 'realDueDate' ? toDateOrMin(second.info?.dueDateOverridden || second.info?.dueDate) : second[sortColumn as keyof typeof second] as Date) ?? new Date(-8640000000000000)).getTime();

  return sortDirectionAscending ? a - b : b - a;
}

export function dispatchSort(a: DocumentClient, b: DocumentClient, sortColumn: string, sortDirectionAscending: boolean, caseData?: Case[]) {
  if (sortColumn === 'name') {
    const compareResult = a?.name?.localeCompare(b?.name ?? "") ?? -1;
    return sortDirectionAscending ? compareResult : -compareResult;
  }
  else if (sortColumn === 'type') {
    const aType = a?.info?.documentSubType ?? DocumentSubType.Unknown;
    const bType = b?.info?.documentSubType ?? DocumentSubType.Unknown;

    let compareResult;
    if (aType > bType) {
      compareResult = 1;
    } else if (aType < bType) {
      compareResult = -1;
    } else {
      compareResult = 0;
    }

    return sortDirectionAscending ? compareResult : -compareResult;
  }
  else if (sortColumn === 'propoundingParty') {
    const compareResult = a?.info?.propoundingPartyName?.localeCompare(b?.info?.propoundingPartyName ?? "") ?? -1;
    return sortDirectionAscending ? compareResult : -compareResult;
  }
  else if (sortColumn === 'case') {
    if (!caseData) {
      return 0;
    }

    const aName = caseData.find(c => c.id === a.caseId)?.shortTitle ?? "";
    const bName = caseData.find(c => c.id === b.caseId)?.shortTitle ?? "";

    const compareResult = aName.localeCompare(bName);
    return sortDirectionAscending ? compareResult : -compareResult;
  }
  else if (sortColumn === 'respondingParty') {
    const compareResult = a?.info?.respondingPartyName?.localeCompare(b?.info?.respondingPartyName ?? "") ?? -1;
    return sortDirectionAscending ? compareResult : -compareResult;
  }

  return sortDocumentDates(a, b, sortColumn, sortDirectionAscending);
}

export function toDateOrMin(val?: string | null) {
  return val ? new Date(val) : new Date(-8640000000000000);
}

export default function DocumentList({
  documents,
  archiveDocument,
  updateDocument,
  allCases,
  allClients,
  downloadForm,
  downloadRequest,
  externalCommunications,
  removeClientAccess,
  updateCommunication,
  useBridge
}: {
  documents: DocumentClient[];
  archiveDocument: (document: DocumentClient) => Promise<void>;
  updateDocument: (document: DocumentClient, _case?: Case, client?: Client) => Promise<void>;
  allCases?: Case[],
  allClients?: Client[]
  downloadForm: (document: DocumentClient, formId: string, formTypeName: string) => Promise<void>;
  downloadRequest: (document: DocumentClient) => Promise<void>;
  externalCommunications?: ViewCommunication[],
  removeClientAccess: (communication: ViewCommunication) => Promise<void>;
  updateCommunication: (communication: ViewCommunication) => Promise<void>;
  useBridge: boolean;
}) {
  const { firm } = useAuth()!;
  const [showEditDueDate, setShowEditDueDate] = useState<DocumentClient | undefined>();
  const [showSetCase, setShowSetCase] = useState<DocumentClient | undefined>();
  const [dueDateOverride, setDueDateOverride] = useState<string | undefined>();
  const [sortColumn, setSortColumns] = useState('uploadDate');
  const [sortDirectionAscending, setSortDirectionAscending] = useState(false);
  const { jurisdictions } = useAuth()!;
  const history = useHistory();

  function getCommunicationForDoc(doc: DocumentClient) {
    return externalCommunications?.filter(c => c.objectId === doc.id)?.sort(sortCommunications)?.[0];
  }

  //TODO: when multiple jx per firm need to revisit to determine how to know to show DueDate stuff
  const jurisdiction = useMemo(() => {
    if (!jurisdictions || !firm) {
      return undefined;
    }

    const firmJx = firm.offices?.find(x => x.jurisdictions?.length)?.jurisdictions![0]?.jurisdictionId ?? CaliforniaId;
    const jx = jurisdictions?.find(j => j.id === firmJx);
    return jx;
  }, [jurisdictions, firm]);

  function closeDueDateModal() {
    setShowEditDueDate(undefined);
    setDueDateOverride(undefined);
  }

  async function saveDocumentDueDate(showEditDueDate: DocumentClient): Promise<void> {
    showEditDueDate.info!.dueDateOverridden = dueDateOverride;
    await updateDocument(showEditDueDate);
    closeDueDateModal();
  }

  function toDateOrNow(val?: string | null) {
    return val ? DateTime.fromISO(val) : DateTime.now();
  }

  function changeSort(column: string) {
    if (column === sortColumn) {
      setSortDirectionAscending(!sortDirectionAscending);
    } else {
      setSortColumns(column);
      setSortDirectionAscending(false);
    }
  }

  function addDueDateDays(days?: number) {
    const existing = toDateOrNow(showEditDueDate?.info?.dueDateOverridden || showEditDueDate?.info?.dueDate);

    const newDate = days ? existing.plus({ days }) : existing.plus({ month: 1 });

    setDueDateOverride(newDate.toFormat('yyyy-MM-dd'));
  }

  function createReview(doc: DocumentClient) {
    history.push(`/documents/${doc.id}/client-feedback`);
  }

  function getListTableHeaders() {
    return <tr>
      <th></th>
      <th
        onClick={() => changeSort('name')}
        className={`clickable ${sortColumn === 'name' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>
        Document Name
      </th>
      <th
        onClick={() => changeSort('case')}
        className={`clickable ${sortColumn === 'case' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>
        Case
      </th>
      <th style={{ width: '93px' }}
        onClick={() => changeSort('uploadDate')}
        className={`clickable ${sortColumn === 'uploadDate' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>
        Upload Date
      </th>
      <th
        onClick={() => changeSort('type')}
        className={`clickable ${sortColumn === 'type' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>
        Document Type
      </th>
      {!!jurisdiction?.supportsDueDates && <>
        <th onClick={() => changeSort('realDueDate')}
          className={`clickable ${sortColumn === 'realDueDate' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>Due Date</th>
        <th></th>
      </>}
      <th></th>
      <th></th>
    </tr>
  }

  function getListTableHeadersBridge() {
    return <tr>
      <th></th>
      <th
        onClick={() => changeSort('name')}
        className={`clickable ${sortColumn === 'name' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>
        Document Name
      </th>
      <th style={{ width: '93px', minWidth: '93px' }}
        onClick={() => changeSort('type')}
        className={`clickable ${sortColumn === 'type' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>
        Doc Type
      </th>
      <th
        onClick={() => changeSort('case')}
        style={{ minWidth: '140px', width: '140px' }}
        className={`clickable ${sortColumn === 'case' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>
        Case
      </th>
      <th style={{ width: '93px' }}
        onClick={() => changeSort('uploadDate')}
        className={`clickable ${sortColumn === 'uploadDate' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>
        Upload Date
      </th>
      {!!jurisdiction?.supportsDueDates && <>
        <th onClick={() => changeSort('realDueDate')}
          style={{ width: '93px' }}
          className={`clickable ${sortColumn === 'realDueDate' ? 'sorting' : ''} ${sortDirectionAscending ? 'ascending' : 'descending'}`}>Due Date</th>
        <th></th>
      </>}
      <th style={{ width: '275px', minWidth: '275px' }}>
        Client Activity
      </th>
      <th style={{ width: '120px' }}></th>
      <th></th>
    </tr>
  }

  const updateDocumentName = async (newName: string, document: DocumentClient): Promise<void> => {
    const updatedDocument = { ...document, name: newName };
    await updateDocument(updatedDocument);
  };

  return (
    <>
      <table id="document-list-table">
        <thead>
          {useBridge ? getListTableHeadersBridge() : getListTableHeaders()}
        </thead>
        <tbody>
          {documents.sort((a, b) => dispatchSort(a, b, sortColumn, sortDirectionAscending, allCases)).map((doc) => (
            useBridge ?
              <DocumentRowWithReview
                key={doc.id}
                doc={doc}
                archiveDocument={archiveDocument}
                editDueDate={!!jurisdiction?.supportsDueDates ? setShowEditDueDate : undefined}
                _case={doc.caseId ? allCases?.find(x => x.id === doc.caseId) : undefined}
                setShowSetCase={doc.caseId ? undefined : setShowSetCase}
                downloadForm={downloadForm}
                downloadRequest={downloadRequest}
                createReview={createReview}
                communication={getCommunicationForDoc(doc)}
                removeClientAccess={removeClientAccess}
                updateCommunication={updateCommunication}
                updateDocumentName={(name) => updateDocumentName(name, doc)} /> :
              <DocumentRow
                key={doc.id}
                doc={doc}
                archiveDocument={archiveDocument}
                editDueDate={!!jurisdiction?.supportsDueDates ? setShowEditDueDate : undefined}
                _case={doc.caseId ? allCases?.find(x => x.id === doc.caseId) : undefined}
                setShowSetCase={doc.caseId ? undefined : setShowSetCase}
                downloadForm={downloadForm}
                downloadRequest={downloadRequest}
              />
          ))}
        </tbody>
      </table>
      <SelectCaseModal doc={showSetCase} onClose={() => setShowSetCase(undefined)} saveCaseClient={updateDocument} allCases={allCases} allClients={allClients} />
      <FormModal
        show={!!showEditDueDate}
        title={`${showEditDueDate?.info?.dueDateOverridden || showEditDueDate?.info?.dueDate ? 'Edit' : 'Set'} Due Date`}
        size='sm'
        confirmText='Confirm'
        cancelText='Cancel'
        onClose={closeDueDateModal}
        onConfirm={() => saveDocumentDueDate(showEditDueDate!)}
      >
        {(showEditDueDate?.info?.dueDateOverridden || showEditDueDate?.info?.dueDate) &&
          <div className='add-x-days'>
            Add <Button variant='link' onClick={() => addDueDateDays(7)} >7 days</Button>, <Button variant='link' onClick={() => addDueDateDays(14)}  >14 days</Button>, <Button onClick={() => addDueDateDays()} variant='link' >1 month</Button>
          </div>
        }
        <Form.Control type='date' className='date-picker' value={dueDateOverride || showEditDueDate?.info?.dueDateOverridden || showEditDueDate?.info?.dueDate || undefined}
          onChange={(e) => setDueDateOverride(e.target.value)} />
      </FormModal>
    </>
  );
}


